import { Button, Grid2, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import GMAddress from "../../shared/GMAddress";
import { Add, Delete, Remove } from "@mui/icons-material";
import { FieldArray } from "formik";
import PaperWithCaption from "../../shared/PaperWithCaption";
import { v4 as uuidv4 } from 'uuid';

const ViaLocations = (props: any) => {
    const { formik } = props

    const newLocation = () => {
        return { id: uuidv4(), time: new Date(), address: '' }
    }

    if (!formik?.values?.viaLocations) return <></>

    return <PaperWithCaption caption="Via">
        <FieldArray name="viaLocations"
            render={(arrayHelpers) =>
                <>
                    <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Tooltip title="Add Last">
                            <Button sx={{ marginLeft: "auto" }} onClick={() => arrayHelpers.push(newLocation())}><Add /></Button>
                        </Tooltip>
                    </Stack>
                    <Grid2 container spacing={1}>
                        {formik.values.viaLocations.map((location: any, index: number) =>
                            <>
                                <Grid2 size={3}>
                                    <DateTimePicker
                                        name={`viaLocations[${index}].time`}
                                        label="Time"
                                        format="DD/MM/YYYY HH:mm"
                                        ampm={false}
                                        value={dayjs(location.time)}
                                        onChange={(newValue: any) => { formik.setFieldValue(`viaLocations[${index}].time`, newValue.format('YYYY-MM-DDTHH:mm:ss')); }}
                                        slotProps={{ textField: { fullWidth: true } }}
                                    />
                                </Grid2>
                                <Grid2 size={7}>
                                    <GMAddress
                                        key={`viaLocations[${location.id}].address`}
                                        label="Address"
                                        name={`viaLocations[${index}].address`}
                                        formik={formik}
                                        initValue={location.address}>
                                    </GMAddress>
                                </Grid2>
                                <Grid2 size={1} sx={{ display: "flex", alignItems: "center" }}>
                                    <Tooltip title="Insert">
                                        <IconButton onClick={() => arrayHelpers.insert(index, newLocation())}><Add /></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Remove">
                                        <IconButton onClick={() => arrayHelpers.remove(index)}><Remove /></IconButton>
                                    </Tooltip>
                                </Grid2>
                            </>
                        )}
                    </Grid2>
                </>
            } />

    </PaperWithCaption>
}

export default ViaLocations