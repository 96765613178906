import jwtDecode from "jwt-decode";
import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import useAppContext from "./useAppContext";

const useAuthContext = () => {
  const { user, setUser } = useContext(AuthContext);
  const { config, setConfig } = useAppContext()

  const isAuthenticated = () => {
    // not logged in
    if (user == null) return false;

    // expired
    if (user.expireOn < new Date()) {
      return false;
    }

    return true;
  };

  const getToken = () => {
    if (!isAuthenticated) return null;

    return user?.token;
  };

  const login = async (email: string, password: string) => {
    const url = `${window.__app_env.apiUrl}/token/createToken`;

    const body: any = {
      email: email,
      password: password,
    };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data: any = await response.json();
    const decodedToken: any = jwtDecode(data.token);

    data.expireOn = new Date(decodedToken.exp * 1000);

    setUser(data);
    setConfig({ ...config, showMenu: true })

    sessionStorage.setItem("user", JSON.stringify(data));

    return data;
  };

  const logout = () => {
    setUser(null);
    setConfig({ ...config, showMenu: false })

    sessionStorage.removeItem("user");
  };

  useEffect(() => {
    // load user from local storage
    const st = sessionStorage.getItem("user");
    if (st != null) {
      const user: any = JSON.parse(st);
      setUser(user);
    }
  }, [setUser]);

  return { user, login, isAuthenticated, getToken, logout };
};

export default useAuthContext;
