import { useEffect, useState } from "react";
import "./App.css";

import Header from "./components/Header";
import { UserInfo } from "./contexts/AuthContext";
import AuthContextProvider from "./contexts/AuthContextProvider";
import Home from "./components/Home";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from "notistack";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import dayjs from 'dayjs';
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import AppContextProvider from "./contexts/AppContextProvider";
import { ConfigInfo } from "./contexts/AppContext";
import { Box, Divider, Stack } from "@mui/material";
import SideMenu from "./components/SideMenu";
dayjs.extend(utc);
dayjs.extend(timezone);


function App() {
  const [config, setConfig] = useState<ConfigInfo>({ showMenu: false });
  const [user, setUser] = useState<UserInfo>();

  //test
  return (
    <>
      <AppContextProvider value={{ config, setConfig }}>
        <AuthContextProvider value={{ user, setUser }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider>
              <APIProvider apiKey={window.__app_env.gmApiKey}>
                <Stack direction="column" sx={{ height: '100vh' }}>
                  <Header />
                  <Box sx={{ flexGrow: 1 }}>
                    <Stack direction="row" sx={{ height: '100%' }}>
                      {config?.showMenu &&
                        <Box sx={{ flexBasis: 250 }}>
                          <SideMenu />
                        </Box>
                      }
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{ flexGrow: 1 }}>
                        <Home />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </APIProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </>
  );
}

export default App;
