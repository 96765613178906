import React from "react";
import {
  AppBar,
  Divider,
  IconButton,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { LocalTaxi, Man, PersonOutline } from "@mui/icons-material";
import useAuthContext from "../hooks/useAuthContext";
import { Menu } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import useAppContext from "../hooks/useAppContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 2,
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Header = () => {
  const classes = useStyles();

  const { isAuthenticated, user, logout } = useAuthContext();
  const { config, setConfig } = useAppContext()
  const navigate = useNavigate();

  const onLogout = () => {
    logout();

    handleCloseMenu();
  };

  const [menuAnchorEl, setMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setConfig({ ...config, showMenu: !config?.showMenu })
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setConfig({ ...config, showMenu: false })
  };

  const isAuth = isAuthenticated();

  const goto = (path: string) => {
    navigate(path)
    handleCloseMenu()
  }

  return (
    <AppBar position="sticky">
      <Toolbar>
        {isAuth && (
          <>
            <IconButton
              edge="start"
              size="large"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              {config?.showMenu ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            {/* <Menu
              anchorEl={menuAnchorEl}
              open={config?.showMenu ?? false}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => goto("/jobs")}>Jobs</MenuItem>
              <MenuItem onClick={() => goto("/booking-advise")}>Booking Advise</MenuItem>
              <Divider component="li" />
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu> */}
          </>
        )}
        <Typography variant="h6" className={classes.title}>
          {window.__app_env.appName}
        </Typography>
        {isAuth && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PersonOutline />
            {user?.email.substring(0, user?.email.indexOf("@"))}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
