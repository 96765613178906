import { Divider, Menu, MenuItem, MenuList } from "@mui/material"
import { useNavigate } from "react-router";
import useAuthContext from "../hooks/useAuthContext";
import { useState } from "react";
import { useLocation } from 'react-router'

const SideMenu = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { logout } = useAuthContext();

    const onLogout = () => {
        logout();
    };

    const goto = (path: string) => {
        navigate(path)
    }

    return <>
        <MenuList>
            <MenuItem selected={location.pathname === '/jobs'} onClick={() => goto("/jobs")}>Jobs</MenuItem>
            <MenuItem selected={location.pathname === '/booking-advise'} onClick={() => goto("/booking-advise")}>Booking Advise</MenuItem>
            <Divider component="li" />
            <MenuItem onClick={onLogout}>Logout</MenuItem>
        </MenuList>
    </>
}

export default SideMenu