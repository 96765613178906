import { AppContext } from "./AppContext";

const AppContextProvider = (props: any) => {
  return (
    <AppContext.Provider value={props.value}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
