import { Paper, Typography } from "@mui/material"

const PaperWithCaption = (props: any) => {
    const { children, caption } = props

    return <Paper style={{ position: "relative", padding: 10, marginTop: 10 }}>
        <Typography sx={{ position: "absolute", top: -10, color: "grey", background: "white", paddingLeft: 0.5, paddingRight: 0.5 }} variant="caption">{caption}</Typography>
        {children}
    </Paper>
}

export default PaperWithCaption