import React from "react";
import useAuthContext from "../hooks/useAuthContext";
import Jobs from "./jobs/Jobs";
import Login from "./Login";
import NoMatch from "./NoMatch";
import BookingAdvise from "./booking-advise/BookingAdvise";
import { Route, Routes } from "react-router";

const Home = () => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated()) return <Login />;

  return (
    <Routes>
      <Route path="/" element={<Jobs />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/booking-advise" element={<BookingAdvise />} />
    </Routes>
  );
};

export default Home;
