import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";

const useGetApi = (props: any) => {
  const { url, isDefered = false } = props
  const { getToken } = useAuthContext();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (isDefered) return;
    if (token == null) return;

    onRefresh();
  }, [url, token]);

  const onRefresh = () => {
    setIsLoading(true);

    const apiUrl = `${window.__app_env.apiUrl}${url}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { data, isLoading, onRefresh };
};

export default useGetApi;
