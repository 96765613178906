import {
  Grid,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
dayjs.extend(duration);
dayjs.extend(relativeTime);

const GMRouteMap = (props: any) => {
  const { center, route, showResult } = props;

  const [map, setMap] = useState<any>();
  const [renderer, setRenderer] = useState<any>();
  const [result, setResult] = useState<any>();

  useEffect(() => {
    const mapDiv: any = document.getElementById("map");
    const map = new google.maps.Map(
      mapDiv,
      {
        center: new google.maps.LatLng(center.latitude, center.longitude),
        zoom: 13,
      }
    );
    setMap(map);
    var directionsRenderer = new google.maps.DirectionsRenderer();
    setRenderer(directionsRenderer);
  }, []);

  const format = (seconds: number) => {
    const d: any = dayjs.duration(seconds, "seconds");
    let st: string = "";

    if (d.years() > 0) st += ` ${d.years()} years`;
    if (d.months() > 0) st += ` ${d.months()} months`;
    if (d.days() > 0) st += ` ${d.days()} days`;
    if (d.hours() > 0) st += ` ${d.hours()} hours`;
    if (d.minutes() > 0) st += ` ${d.minutes()} mins`;

    return st;
  };

  useEffect(() => {
    if (!map) return;
    if (!renderer) return

    const updateRoute = async () => {
      if (!route) return;

      if (
        route.fromAddress !== "" &&
        route.fromAddress !== undefined &&
        route.toAddress !== "" &&
        route.toAddress !== undefined
      ) {
        try {
          var directionsService = new google.maps.DirectionsService();
          var request: any = {
            origin: route.fromAddress,
            destination: route.toAddress,
            travelMode: "DRIVING",
          };

          if (route.via?.length > 0) {
            let wps: any = [];
            route.via.map((v: any) => {
              if (!v.address || v.address === '') return;
              wps.push({ location: v.address, stopover: true });
            });
            if (wps.length > 0) request.waypoints = wps;
          }

          const response: any = await directionsService.route(request);
          if (response.status === "OK") {
            renderer.setMap(map);
            renderer.setDirections(response);

            if (response.routes.length > 0) {
              const route = response.routes[0];
              let distance: number = 0;
              let duration: number = 0;
              route.legs.forEach((leg: any) => {
                distance += leg.distance.value;
                duration += leg.duration.value;
              });

              setResult?.({
                route,
                distance: `${(distance / 1_000).toFixed(0)} km`,
                duration: format(duration),
              });
            }
            return;
          }
        } catch (err: any) {
          //enqueueSnackbar(err.message, { variant: "error" });
        }
      }

      renderer?.setMap(null);
      map.setCenter(new google.maps.LatLng(center.latitude, center.longitude));
      setResult(null);
    };

    updateRoute();
  }, [route]);

  return (
    <>
      <Paper sx={{ p: 1 }}>
        <Grid2 container>

          <Grid2 size={6}>
            {showResult && result?.route && (
              <TableContainer sx={{ mb: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell align="right">Duration</TableCell>
                      <TableCell align="right">Distance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.route.legs.map((leg: any, index: number) => (
                      <TableRow
                        key={`leg-${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {leg.start_address}
                        </TableCell>
                        <TableCell>{leg.end_address}</TableCell>
                        <TableCell align="right">{leg.duration.text}</TableCell>
                        <TableCell align="right">{leg.distance.text}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2}>Driving Total</TableCell>
                      <TableCell align="right">{result.duration}</TableCell>
                      <TableCell align="right">{result.distance}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid2>
          <Grid2 size={6}>
            <div
              id="map"
              style={{
                marginTop: 1,
                width: "100%",
                height: "400px",
              }}
            ></div>
          </Grid2>
        </Grid2>
      </Paper>
    </>
  );
};

export default GMRouteMap;
