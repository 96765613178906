import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";

const usePostApi = (props: any) => {
  const { url } = props
  const { getToken } = useAuthContext();
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false);

  const token = getToken();

  const onUpdate = (model: any) => {
    setIsLoading(true);

    const apiUrl = `${window.__app_env.apiUrl}${url}`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onUpdateNoResponse = (model: any) => {
    setIsLoading(true);

    const apiUrl = `${window.__app_env.apiUrl}${url}`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return { data, isLoading, onUpdate, onUpdateNoResponse };
};

export default usePostApi;
