import { createContext } from "react";

export interface ConfigInfo {
  showMenu: boolean,
}

export interface AppData {
  config?: ConfigInfo;
  setConfig: (config: any) => void;
}

export const AppContext = createContext<AppData>({
  setConfig: () => { },
});
